a.button, button {
  padding: 0.8rem 1.6rem;
  transition: 0.1s all;
  border-radius: 999px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: none;
  vertical-align: bottom;

  font-weight: bold;
  text-decoration: none;
  color: unset;

  &:hover {
    // copied from commonapp.org
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
      0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
      0 8px 16px rgba(0, 0, 0, 0.11);
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }

  &.celebrate {
    border: 2px solid var(--text);
  }

  &.green {
    background: #008542;
    color: white;
  }

  // only if icon
  .icon {
    margin-right: 0.5rem;
  }
}
